/* Catering */
.backto-rest {
  display: block;
  padding: 20px 25px 0;
}
.common-inner-banner {
  position: relative;
  padding: 20px 10px 10px;
  color: #000;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  background-size: cover;
  min-height: 160px;
  text-align: center;
}
.catering-options-inner > ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.catering-options-inner > ul > li {
  width: 32.4%;
  margin: 0 1.4% 20px 0;
  background: #fff;
  position: relative;
  box-shadow: 0 3px 28px 0 rgba(1, 1, 1, 0.1);
  z-index: 1;
  cursor: pointer;
}
.catering-options-inner > ul > li .home-option-img {
  position: relative;
  padding-bottom: 56.5%;
}
.catering-options-inner > ul > li .home-option-img img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  margin: auto;
  object-fit: cover;
}
.catering-options-inner > ul > li .home-option-desc {
  padding: 15px;
}
.catering-options-inner > ul > li .home-option-desc h5 {
  font-size: 18px;
  margin-bottom: 15px;
}
.receipt_popup,
.receipt_cateringpopup {
  max-width: 620px;
}
.receipt_popup .pouup_in,
.receipt_cateringpopup .pouup_in {
  padding: 0;
}
.receipt_popup .tnk-you,
.receipt_cateringpopup .tnk-you {
  padding: 0;
}
.receipt_popup .tnk-detail,
.receipt_cateringpopup .tnk-detail {
  margin-top: 0;
}
.receipt_popup .Viewreceipt,
.receipt_cateringpopup .Viewreceipt {
  margin: 30px 0;
}
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
  background: rgba(1, 1, 1, 0.9);
}
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
  background: rgba(0, 0, 0, 0.9);
}

.catering .ttsplitter span {
  background: #fff;
}

.catering .ttsplitter {
  margin: 0 auto 20px;
}
.date_list {
  margin-bottom: 30px;
  position: relative;
  padding: 40px 120px 30px;
  border: 2px solid #dddddd;
  background: #fff;
  border-radius: 6px;
}

.date_list:after {
  content: "";
  display: table;
  clear: both;
}

.datelist_bracktime h3,
h3.choose-eventtype {
  text-align: center;
  font-size: 26px;
  margin: 0 0 20px 0;
}

.ordering-more {
  text-align: center;
}

.ordering-more .button.no {
  background: #000;
}

.ordering-more .button.yes {
  background: #769e90;
}
.more-time-slots {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 476px;
  margin: 0 auto;
}
.more-time-slots a {
  width: 49%;
  margin: 0;
  padding: 17px 5px 16px;
  border-radius: 5px;
  font-size: 19px;
}

#nobtn_id {
  margin-right: 5px;
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
  background: #333;
}

.ordering-more h3 {
  color: #3d3d3d;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 15px 0;
}

.date_list h4 {
  color: #3d3d3d;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 15px 0;
}

.venue-details-inner h3 {
  display: block;
  font-size: 14px;
  color: #3c3a3a;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
  padding-left: 5px;
}

.making-catering {
  width: 36%;
  float: left;
  padding: 5px 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.17);
}

.making-catering p {
  margin: 0;
}

.making-place {
  font-weight: bold;
  font-size: 16px;
}

.making-place.making-place-padding {
  display: block;
}

.change-location a {
  text-decoration: underline;
  font-size: 17px;
  color: #000;
}

.change-location a:hover {
  color: #769e90;
}

.event-time {
  width: 33%;
  padding: 5px 0px 5px 20px;
}

.event-time .title4 {
  margin-bottom: 3px;
}

.event-time .row {
  margin: 0 -4px;
}

.event-time [class*="col-"] {
  padding: 0 4px;
}

.event-time .form-group {
  margin: 0;
}

.event-time .form-control {
  height: 57px;
}

.order-select-brktime ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.order-select-brktime ul .time-cls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
  margin: 0;
  position: relative;
  border: 1px solid #e4e4e4;
}

.order-select-brktime ul .time-cls label {
  width: calc(100% - 200px);
  font-size: 18px;
  font-weight: bold;
  color: #020202;
  text-transform: uppercase;
}

.viewmenu-top-div {
  margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
  padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
  font-size: 30px;
  top: 5px;
}
ul li .cat-product-info {
  background: rgba(0, 0, 0, 0.6);
  padding: 45px 15px 45px;
  color: #fff;
  line-height: 1.4;
  font-size: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.35s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
ul li .cat-product-info h4 {
  color: #ffffff;
  margin: 0 0 5px 0;
}
.cat-product-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.cat-product-button a {
  margin: 0;
  display: block;
  height: 45px;
  padding: 15px 10px;
}
.btn_download {
  font-size: 18px;
  color: #fff;
  padding: 8px 20px 7px;
  height: 38px;
  border: 0;
  border-radius: 30px;
  background: #769e90;
  -webkit-box-shadow: 0 2px 0px 0px #0b0405;
  box-shadow: 0 2px 0px 0px #0b0405;
  margin: 6px 0px 12px;
}

.btn_download:hover {
  background: #ecdfcc;
  color: #0b0405;
  -webkit-box-shadow: 0 2px 0px 0px #b1b1b1;
  box-shadow: 0 2px 0px 0px #b1b1b1;
}

.product_modifiers_listinner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cross_multi {
  font-size: 15px;
  color: #5d5d5d;
  margin: 0 0px 5px 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 6px 12px;
  z-index: 1;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  border: 1px solid #d6d6d6;
  line-height: 1;
  border-radius: 4px;
  text-align: center;
}

.checkout_time {
  padding: 0px 0px 20px;
}

.checkout_time label {
  color: #000000;
  line-height: 40px;
  padding: 4px 0px;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: bold;
}

.total_amt {
  background: #1c1c1c;
  padding: 35px 70px 30px 50px;
}

.total_amt .subtotal {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.total_amt .total {
  font-size: 25px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.total_amt .rt {
  text-align: right;
}

.brline {
  background: #f5f5f5;
  height: 2px;
}

.download-quote {
  padding: 20px 0px 0px;
  text-align: center;
}

.download-quote .btn {
  font-size: 18px;
  color: #fff;
  padding: 7px 20px;
  border: 0;
  background: #769e90;
}

.catering_thak_you .tnk-header {
  text-align: unset;
}

.catering_thak_you .tnk-detail {
  text-align: center;
}

.catering_thak_you .tnk-header .tick {
  text-align: center;
}

.catering_thak_you .order_details .qtybxs {
  font-weight: 700;
  color: #000000;
  font-size: 16px;
  line-height: 38px;
  text-align: right;
}

.catering_thak_you .package .package-list {
  padding: 0px 0px 15px 0px;
}

.catering_thak_you .package .title {
  border-bottom: 1px solid #000;
}

.catering_thak_you .brline {
  margin-left: 0px;
}
.sprite_lst_icon.catering_icon_t {
  width: 21px;
}
.catering-product-rowlist span.min_pax_spn {
  display: block;
  font-size: 17px;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-family: "Poppins", sans-serif;
}

.catering-product-rowlist .price_nor {
  font-size: 22px;
  display: block;
  padding: 10px 0 0 0;
  font-weight: bold;
}

.dotted-line-top {
  border-top: 1px dotted #ccc;
  padding: 20px 0 0 0;
  margin: 10px 0 0 0;
}

.error_div_cls {
  color: red;
  display: none;
}
.mk-catering-payment .chk-payment-col-radio li {
  margin-right: 0;
}
.catering_receipt_popup .cart_left {
  width: calc(100% - 150px);
  display: flex;
  align-items: flex-start;
}
.catering_receipt_popup .cart_right {
  width: 150px;
}
.receipt_cateringpopup .pouup_in .products_info_item .cart_left h4 {
  padding-left: 0;
}
.receipt_cateringpopup .pouup_in .cart_indvl_price {
  display: block;
  clear: both;
}
.receipt_cateringpopup .pouup_in .pkg-list-lhs > div {
  padding-right: 0;
}
.catering_receipt_popup .cart-settup-list,
.products_info_item {
  padding: 5px 4px 5px 15px;
}
.addon_popup-catering {
  max-width: 780px;
}
.addon_popup-catering .addon_linfo h4 {
  text-transform: capitalize;
  font-size: 17px;
  margin-bottom: 10px;
}
.catering-checkout-total .order_det {
  padding: 0 15px;
}
.catering-checkout-total .cart_footer {
  padding: 10px 15px 15px 15px;
}
.catering-checkout-total .cart_row.grant-total-cls {
  margin-bottom: 0;
}
.btn_big {
  display: block;
  padding: 12px 10px;
  font-size: 16px;
}
.cdd-details-rhs .event_timing .form-group .input_field .custom_select {
  width: calc(100% - 220px);
}
.cdd-details-rhs .products_info_item .cart_left h4 {
  padding-left: 0;
}
.cdd-details-rhs .event_timing {
  padding-bottom: 5px;
  border-bottom: 0;
}
.catering_receipt_popup .Viewreceipt .tnk-delivery .order-items-maindiv .cart_row.oreder_itm_row .col-sm-cls.cart_left {
  display: block;
}
.catering_receipt_popup .tnk-header .tnk-delivery .order-items-maindiv .cart_row.oreder_itm_row .row.oreder-row-inv {
  width: 100%;
}

.catering_receipt_popup .tnk-header .tnk-delivery .order-items-maindiv .cart_row.oreder_itm_row .row.oreder-row-inv .col-xs-7.cart_left {
  padding: 0;
}

.catering_receipt_popup .tnk-header .tnk-delivery .order-items-maindiv .cart_row.oreder_itm_row .row.oreder-row-inv .col-xs-5.cart_right.text-right {
  padding: 0;
}
.catering-thankyou-page .tnk-chk-order .button:hover {
  background: #db203b;
  color: #000;
}

.catering-thankyou-page .tnk-chk-order a.button {
  color: #fff;
}
.catering-thankyou-page .tnk-you .thank-order-detaildiv .delivery-cart-div {
  display: block;
}
.catering_banner {
  position: relative;
  height: 305px;
}

.catering_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.catering_products_main {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 23px;
}

.catering_products_main .products_sidebar {
  width: 280px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
}

.catering_products_main .products_sidebar .product_sidebar_top {
  position: relative;
  display: none;
}

.catering_products_main .products_sidebar .product_sidebar_top h4 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 2px;
}

.catering_products_main .products_sidebar .product_sidebar_top p {
  font-size: 14px;
  margin: 0;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter img.closed {
  width: 30px;
  opacity: 0;
  position: absolute;
  left: 0;
}

.catering_products_main .products_sidebar .products_sidebar_inner {
  padding: 0 15px;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:first-child {
  padding-top: 10px;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content {
  padding: 15px 0;
  border-bottom: 2px solid #f2f2f2;
  position: relative;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content.active:before {
  opacity: 1;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:first-child:before {
  top: 0;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:before {
  content: "";
  display: block;
  width: 7px;
  height: 42px;
  background-color: #302d2a;
  position: absolute;
  left: -17px;
  top: 6px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content h4 {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 4px;
}

.catering_products_main .clear_ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  border: 2px solid #f3f3f3;
  box-shadow: 0 5px 16px 0 rgb(0 0 0 / 7%);
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li {
  line-height: 1.2;
  margin: 0px 0 0px;
  min-height: auto;
  width: auto;
  border: none;
  text-align: left;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li a {
  border: none;
  text-align: left;
  justify-content: flex-start;
  color: #000;
  line-height: 1.3;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li a:after {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 22px;
  line-height: 1;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li a {
  font-size: 16px;
  font-weight: 600;
  display: block;
  padding: 15px 30px 15px 20px;
  text-transform: capitalize;
  position: relative;
  border-radius: 0px;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li a.active {
  background: #db203b;
  color: #000;
  border: none;
  text-align: left;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li:last-child {
  margin-bottom: 0;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content {
  padding: 15px 0;
  border-bottom: 0px;
  position: relative;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:before {
  content: "";
  display: block;
  width: 7px;
  height: 42px;
  background-color: #302d2a;
  position: absolute;
  left: -17px;
  top: 6px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:last-child {
  border-bottom: none;
}

.catering_products_main .products_list {
  width: calc(100% - 660px);
  margin-top: 30px;
  transition: all 0.4s linear;
}

.no-recrds-found {
  margin: 0;
  min-height: 270px;
  width: 100%;
  font-size: 20px;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
}

.catering_products_main .products_list .products_list_content {
  margin-bottom: 6px;
  margin-bottom: 6px;
  display: block;
  flex-wrap: inherit;
  justify-content: flex-start;
  border: 2px solid #f3f3f3;
  padding: 10px;
}

.catering_products_main .products_list_inner {
  width: 100%;
}

.catering_products_main .products_list_desc {
  width: 100%;
}

.products_list_inner {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.products_list_inner .products_list_image {
  height: 100%;
}

.catering_products_main .products_list_inner .products_list_image img {
  max-height: 335px;
}

.products_list_inner .products_list_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.catering_products_main .products_list_content .products_list_desc {
  padding: 15px 130px 0 0;
  min-height: 100px;
}

.catering_products_main .products_list_desc {
  position: relative;
  padding: 15px 0 37px;
  flex-grow: 1;
}

.catering_products_main .products_list_desc h4 {
  font-size: 19px;
  font-family: "Merriweather", serif;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.4;
  text-transform: inherit;
  margin: 0 0 5px;
  max-width: 440px;
}

.catering_products_main .products_list_desc h4 span {
  font-style: italic;

  text-transform: none;
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.catering_products_main .products_list_desc p {
  line-height: 1.4;
  margin: 0 0 10px;
}

.catering_products_main .products_list_content .products_list_desc .plistbtn_sec {
  left: auto;
  right: 0;
  top: 15px;
  bottom: auto;
}

.products_list_desc .plistbtn_sec {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.products_list_desc .btn {
  text-transform: uppercase;
}

.catering_products_main .btn_green {
  margin-bottom: 0;
  margin-top: 0;
  min-width: 202px;
}
.catering_products_main .btn_green {
  color: #fff;
  display: inline-block;
  border-radius: 8px;
  margin: 0px 0 10px;
  padding: 19px 26px;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  background: #000;
  border: 0;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 0;

  letter-spacing: 3px;
  font-weight: 600;
}

.catering_products_main .btn_green:hover {
  color: #000;
  background: #db203b;
}

.fixed_checkout .btn:hover {
  color: #000;
  background: #db203b;
}

.fixed_checkout .btn {
  color: #fff;
  display: inline-block;
  border-radius: 8px;
  margin: 0px 0 10px;
  padding: 19px 26px;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  background: #000;
  border: 0;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 0;

  letter-spacing: 3px;
  font-weight: 600;
}

.btn_sm {
  min-width: 115px;
}

.catering_products_main .product_orders {
  margin-top: 30px;
  padding: 0;
}
.product_orders.cart_body.catering_cart_list {
  padding: 0;
  width: 100%;
}

.fixed_checkout_order,
.fixed_checkout_order .btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.fixed_checkout_order {
  display: none;
}

.fixed_checkout_order .btn {
  background-color: #000;
  font-size: 17px;
  color: fff;
  height: 50px;
  padding: 11px 10px;
}
.fixed_checkout_order .btn:hover {
  background-color: #db203b;
  color: #fff;
}

.fixed_checkout_order,
.fixed_checkout_order .btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.catering_list_products h2 {
  margin: 0;
  font-family: "Merriweather", serif;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #000;
  letter-spacing: 0.3px;
  width: 100%;
}

.catering_event h3 {
  margin: 0;
  font-family: "Merriweather", serif;
  font-size: 15px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #000;
  width: 100%;
}

.product_orders .react-datepicker__input-container:after {
  content: "";
  background: url(../images/arrow-down.png) no-repeat center;
  width: 45px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  pointer-events: none;
  height: 46px;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter img.closed {
  width: 30px;
  opacity: 0;
  position: absolute;
  left: 0;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter.close_filter img.closed {
  opacity: 0;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter.close_filter img {
  opacity: 1;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter img.closed {
  opacity: 1;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter img {
  opacity: 0;
}

.catering_products_main .products_sidebar_mobile .products_sidebar_inner .products_sidebar_content:first-child {
  padding-top: 10px;
}

.catering_products_main .products_sidebar_mobile .products_sidebar_inner .products_sidebar_content {
  padding: 15px 0;
  border-bottom: 2px solid #f2f2f2;
  position: relative;
}

.catering_products_main .products_sidebar_mobile .products_sidebar_inner .products_sidebar_content h4 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 4px;
}

.catering_products_main .products_sidebar_inner ul li a {
  border: 0px;
}

.package_details .package_details_left .slick-arrow.slick-prev {
  left: 0;
  background-image: url(../images/next-arrow.png);
  background-position: 35%;
}

.package_details .package_details_left .slick-arrow {
  height: 46px;
  width: 26px;
  background-color: rgba(0, 0, 0, 0.7);
  background-repeat: no-repeat;
  background-size: 12px;
  font-size: 0px;
  border-radius: 0px;
}

.package_details .package_details_left .slick-arrow.slick-next {
  right: 0;
  background-image: url(../images/next-arrow.png);
  background-position: 60%;
  transform: rotate(180deg);
  top: 40%;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
}

.cat-modal {
  padding: 0px 15px;
  border: 0px;
}
.product_acc_grp {
  margin-bottom: 30px;
}
.product_acc_grp .accordion {
  margin: 0 0 10px;
}
.product_acc_grp .accordion .accordion_body {
  /* padding-top: 15px; */
}

.product_acc_grp .product_card_desc .desc_left {
  width: 100%;
}

.product_acc_grp .product_card_desc .desc_right {
  width: 610px;
}

.product_acc_grp .product_det_cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.product_acc_grp .product_det_cards .det_card {
  width: 32%;
  padding: 0;
  margin: 0 2% 20px 0;
  position: relative;
}

.product_acc_grp .product_det_cards .det_card .prd_close img {
  width: 20px;
  top: 6px;
  position: relative;
  opacity: 1;
}
.product_acc_grp .product_det_cards .det_card:nth-child(3n) {
  margin-right: 0;
}

.product_acc_grp .product_det_cards .det_inner {
  border: 1px solid #dad9d9;
  height: 100%;
}

.product_acc_grp .product_det_cards .det_inner_image img {
  width: 100%;
  height: 225px;
  object-fit: cover;
  object-position: center;
}

.product_acc_grp .product_det_cards .det_inner_content {
  padding: 14px 15px 17px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: calc(100% - 225px);
}

.product_acc_grp .product_det_cards .det_inner_image {
  width: 100%;
  height: 225px;
  display: flex;
  --webkit-box-pack: center;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
}

.product_acc_grp .product_det_cards .det_inner_content h5 {
  font-size: 17px;
  font-weight: 600;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 15px;
}
.product_acc_grp .product_det_cards .det_inner_content {
  border: 0;
}
.product_acc_grp .product_det_cards .det_inner_content .form-group {
  margin-bottom: 10px;
}

.product_acc_grp .product_det_cards .det_inner_content p {
  margin: 0 0 15px;
  text-align: center;
}

.product_acc_grp .product_det_cards .det_inner_content i {
  font-weight: 700;
}

.product_acc_grp .product_det_cards .det_inner_content .custom_checkbox {
  width: 100%;
  line-height: 1.4;
  position: relative;
  display: inline-block;
}

.product_acc_grp .product_det_cards .det_inner_content .btn.btn_dark,
.product_det_cards .det_inner_content .btn.btn_green {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  margin: 0px;
  padding: 12px 15px;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  background: #282828;
  border: 0;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 0;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.catering_products_main .products_sidebar .product_sidebar_top .package_filter.close_filter img {
  opacity: 1;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter img {
  opacity: 0;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter.close_filter img.closed {
  opacity: 0;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter img.closed {
  opacity: 1;
}
.catering_receipt_popup .pkgslist_additionrow {
  display: block;
}

.mk-catering-payment .chk-payment-col-radio li label {
  padding-left: 20px !important;
}

.catering_delivery_total_left h3 {
  font-size: 15px;
}

.catering_delivery_total_left .delivery_total_right {
  font-size: 15px;
}

.catering_delivery_total_left img {
  height: 22px;
}

.event-container .custom_checkbox span {
  display: inline-block;
  line-height: 0;
}

.event-container .custom_checkbox {
  display: inline-block;
  line-height: 22px;
}

.catering_products_main .product_orders .pkgsli_row {
  /* display: block; */
  flex-wrap: wrap;
}

.catering_products_main .pkgsli_row {
  padding: 15px 0 10px;
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.catering_products_main .pkgsli_row .col-sm-8.col-7 {
  width: calc(100% - 110px);
  padding-left: 0px !important;
}

.catering_products_main .pkgsli_row .col-sm-4.col-5.pkgsli_right {
  width: 110px;
  padding-right: 18px !important;
}
.catering_receipt_popup .mCSB_scrollTools {
  display: none !important;
}

.catering_receipt_popup .mCSB_inside > .mCSB_container {
  margin-right: 0px;
}

.catering_receipt_popup .panel-collapse {
  height: 100%;
  max-width: 100%;
}
.product-menu-listing .catering-product-list .products_sidebar_inner {
  width: 83%;
}
.catering-product-list .products_sidebar_inner .slick-slider {
  display: none;
}
.menu-section-catering.menu-section-left .menu-section-left-inner,
.products_sidebar_inner ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.catering-product-list .products_sidebar_inner ul li {
  width: 24.49%;
  min-height: 56px;
  margin: 0 0.5% 5px 0;
}
.menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl.active .menu-title-link,
.menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl .menu-title-link:hover,
.products_sidebar_inner ul li.active a,
.products_sidebar_inner ul li:hover a,
.slick-slider .products_sidebar_content.active a,
.slick-slider .products_sidebar_content a:hover {
  color: #000;
  background: #fff;
}
.catering-product-list .product-lhs {
  box-shadow: none;
  padding: 0;
  margin: 15px 0 0;
  width: calc(100% - 532px);
}
.catering-product-list .product-rhs {
  margin: 15px 0 0;
  width: 530px;
  position: relative;
}
.menu-section-mobile-inner,
.mobile-menu-section-left {
  display: none;
  position: relative;
  width: 100%;
  background: #fff;
}
.product-lhs-inner .menu-section-left {
  border-bottom: 4px solid #e9e9e9;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 3;
}
.product-lhs-inner .products_sidebar_inner ul,
.menu-section-catering.menu-section-left .menu-section-left-inner {
  width: 80%;
  display: flex;
}
.menu-section-catering.menu-section-left .more-menu {
  width: 20%;
}
.menu-section-catering.menu-section-left .more-menu-parent {
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.menu-section-catering.menu-section-left ul.more_categor_info {
  padding: 15px;
}
ul.more_categor_info {
  padding: 0;
  list-style: none;
  min-width: 200px;
  max-width: 320px;
  /* background: #fff; */
  background: #fcf5d5;
  position: absolute;
  right: 0;
  max-height: 60vh;
  overflow-y: auto;
  border-radius: 3px;
  top: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 3px #00000040;
}
.catering-product-list .hcart_dropdown {
  position: inherit;
  pointer-events: all;
  transform: none;
  width: 100%;
  visibility: visible;
  opacity: 1;
}
.catering-product-list .package_details {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 22px;
}
.package_details_left {
  width: 48%;
}
.package_details_right {
  width: 50%;
  margin: 25px 0 0;
}
.package_details_right_inner {
  display: flex;
  justify-content: space-between;
}
.pdr_left {
  width: calc(100% - 160px);
  font-size: 14px;
}
.pdr_left h4 {
  margin: 0 0 15px;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 700;
  color: #060801;
  text-transform: capitalize;
}
.pdr_left p {
  font-size: 14px;
  color: #777777;
}
.package_details_left .slick-slider .slick-list .slick-slide > div {
  width: 100%;
}
.fix-only-lhs {
  display: none;
}
.fix-only-lhs h3 {
  margin-bottom: 15px;
  font-size: 17px;
  text-transform: capitalize;
}
.pdr_right-inner {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pdr_right h4 {
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 700;
  color: #060801;
  text-transform: capitalize;
}
.pdr_right h5 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #353535;
}
.catering-product-list .pdr_right .qty_bx {
  width: 120px;
  height: 38px;
  padding: 0 30px;
}
.catering-product-list .pdr_right .qty_bx span {
  width: 30px;
}
.catering-product-list .pdr_right .proqty_input .catering-product-list .pdr_right .qty_bx span {
  width: 30px;
}
.catering-product-list .pdr_right .product-min-pax {
  font-size: 12px;
  margin: 8px 0 0px;
  color: #666666;
  line-height: 1.1;
}
.catering-product-list .pdr_right .proqty_input {
  text-align: center;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.catering-product-list .product_det_cards > ul {
  width: 100%;
}
.catering-product-list .products_info_item {
  flex-wrap: wrap;
}
.catering-product-list .cart-product-name {
  width: 100%;
}
.catering-product-list .cart-product-name h4 {
  font-size: 17px;
  margin: 0 0 10px 0;
}
.catering-product-list .products_info_options_wrap {
  width: 100% !important;
}
.catering-product-list .custom_checkbox {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}
.catering-product-list .custom_checkbox input {
  margin-bottom: 3px;
  margin-right: 5px;
}

.catering-options-inner > ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.catering-options-inner > ul > li {
  width: 32.4%;
  margin: 0 1.4% 20px 0;
  background: #fff;
  position: relative;
  box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.1);
  z-index: 1;
  cursor: pointer;
}
.catering-options-inner .mobile-choose-option-hover {
  display: none;
}
.catering-product-list .product-rhs .cart_footer {
  width: 100%;
}
.catering-checkout-total .chekout_cart_bar {
  width: 100%;
  box-shadow: none;
}
.catering-options-inner > ul > li:nth-child(3n) {
  margin-right: 0;
}
.catering-options-inner > ul > li .home-option-img img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  margin: auto;
  object-fit: cover;
}
.catering-options-inner > ul > li .home-option-desc {
  padding: 15px;
}
.catering-options-inner > ul > li .home-option-desc h5 {
  font-size: 18px;
  margin-bottom: 15px;
}
.catering-options-inner > ul > li .home-option-img {
  position: relative;
  padding-bottom: 56.5%;
}
.det_inner_content .custom_logo_banner a.btn {
  width: calc(100% - 70px);
  padding: 12px 10px !important;
  font-size: 12px !important;
}
.custom_logo_banner .file-name {
  font-size: 12px;
  width: 70px;
}
.catering-checkout-total .chekout_cart_bar .product_orders.cart_body {
  width: 100%;
}
.thank-order-detaildiv .products_info_item .cart_left h4 {
  padding-left: 0;
}
.catering-omise-payment {
  max-width: 420px;
}
.catering-payment .thank-order-detaildiv {
  max-width: 680px;
}
.catering-payment .tnk-chk-order {
  max-width: 680px;
}
.catering-payment-success .tnk-order h4 {
  color: #fff;
  margin: 0;
}
.catering-product-list .product-lhs-inner {
  padding: 0;
}
.catering-product-list .menu-section-left-inner .menu-list-intvl a {
  padding: 10px 14px;
  line-height: 1.1;
}
.catering-product-list .menu-section-left-inner .menu-list-intvl a:before,
.catering-product-list .more-menu-parent:before {
  height: 4px;
  bottom: -4px;
}
.modi_product_quantity_inc .counter.qty_bx {
  display: flex;
  justify-content: center;
  margin: 0 auto 10px;
  align-items: center;
}
.cdd-details-rhs .product_orders .order_det .order_card .products_info .products_info_item .products_info_options .products_info_options_wrap {
  width: 100%;
}

.product-menu-listing {
  padding: 0;
}

.product-menu-listing .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.product-lhs-inner .products_list {
  padding: 22px 18px 20px;
}
.product_acc_grp .accordion .accordion_title {
  background: #282828;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding: 12px 35px 10px 12px;
  line-height: 1;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body .accordion {
  border: 0;
  border-radius: 0;
}
.product_acc_grp .accordion .accordion_title {
  background: #282828;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding: 12px 35px 10px 12px;
  line-height: 1;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product_acc_grp .accordion {
  margin: 0 0 10px 0;
}
.product_acc_grp .accordion .accordion_title span {
  color: #a2a2a2;
  font-family: "Poppins", sans-serif;
  font-size: 90%;
}
.product_acc_grp .accordion .accordion_title::before,
.product_acc_grp .accordion .accordion_title::after {
  background: #fff;
  content: "";
  display: block;
  height: 2px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transition: transform 0.25s ease-in-out 0s;
  -webkit-transition: transform 0.25s ease-in-out 0s;
  width: 14px;
  z-index: 2;
}
.product_acc_grp .accordion .accordion_title::before {
  content: "";
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}
.product_acc_grp .accordion .accordion_title::after {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.product_acc_grp .accordion .accordion_title.active::before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.product_acc_grp .accordion .accordion_title.active::after {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}
.catering-product-list .product_det_cards > ul {
  list-style: none;
  padding: 30px 0 0 0;
}
.product_orders .product_orders_top {
  background: #000;
  color: #fff;
  display: none;
  position: relative;
  padding: 17px 25px;
}

.product_orders .product_orders_top h4 {
  font-size: 22px;

  font-weight: 400;
  margin: 0;
}

.product_orders .product_orders_top .close_product_orders {
  position: absolute;
  width: 25px;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.product_orders .order_det {
  padding: 10px 13px 16px;
}

.product_orders .order_det .order_card {
  border: 1px solid #302d2a;
  border-radius: 5px;
  margin-bottom: 10px;
}

.product_orders .order_det .order_card .order_card_head {
  background-color: #302d2a;
  color: #fff;
  font-weight: 600;
  padding: 15px 70px 15px 13px;
  position: relative;
}

.product_orders .order_det .order_card .order_card_head a {
  position: absolute;
  right: 13px;
  top: 10px;
}

.product_orders .order_det .order_card .event_timing .input_field {
  display: flex;
  /*flex-wrap: wrap;*/
  align-items: center;
}

.product_orders .order_det .order_card .event_timing .form-group .tooltip_link {
  position: absolute;
  right: 0;
  top: 14px;
  display: block;
}

.product_orders .del_address .form-group .row {
  margin-left: -3px;
  margin-right: -3px;
}

.product_orders .del_address .form-group .row .col-lg-6 {
  padding-left: 3px !important;
  padding-right: 3px !important;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.product_orders .react-datepicker-wrapper {
  width: 100%;
}

.catering_list_products h2 {
  margin: 0;
  font-family: "Merriweather", serif;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #000;
  letter-spacing: 0.3px;
  width: 100%;
}
.product_orders .del_address {
  padding: 0px 20px;
  /* border-bottom: 1px solid #f4f4f4; */
}
.checkout_section .product_orders .del_address {
  padding: 25px 30px 27px;
}

.product_orders .del_address .form-group {
  margin-bottom: 8px;
}
.additionals ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.additionals_setuplist .custom_radio [type="radio"]:checked,
.additionals_setuplist .custom_radio [type="radio"]:not(:checked) {
  position: relative;
  left: 0 !important;
}
ul.additionals-deliverylist {
  padding: 15px 0 10px 30px;
}

.catering-banner-inner {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 6%;
  transform: translate(0, -50%);
}

.catering-banner-inner--product {
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.catering-banner-inner--product .catering-service {
  font-size: 30px;
}

.catering-banner-inner--product .catering-service-sub {
  font-size: 24px;
}

.catering-category-banner {
  position: relative;
  margin-top: 160px;
  height: 414px;
  flex-shrink: 0;
  background: url("../images/louis-hansel.png"), lightgray 50% / cover no-repeat;
}

.catering-category-banner--list {
  position: relative;
  color: white;
  background: url("../images/makisan-events-parties.jpg"), lightgray 50% / cover no-repeat;
}

.catering-category-banner--list h3 {
  color: white;
}

.catering-category-banner--list::after {
  z-index: 10;
  content: "";
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.catering-service {
  display: flex;
  height: 44.651px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 15px;
}
.catering-service-sub {
  display: flex;
  height: 33.488px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.catering-explore-btn {
  text-transform: capitalize;
  padding: 15px 20px;
  margin-top: 30px;
  border-radius: 20px;
  color: #007a4b;
  border: 1.5px solid #007a4b;
  background: #fff;
}

.catering-explore-btn:hover {
  background: #007a4b;
  color: #fff;
}

.caterer-for-you {
  margin-top: 40px;
  display: block;
  text-align: center;
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.catering-desc {
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.catering-link-wrapper {
  display: block;
  text-align: center;
}

.catering-package-list-link {
  display: inline-flex;
  text-transform: uppercase;
  padding: 14px 50px;
  border-radius: 20px;
  color: #007a4b;
  border: 1.5px solid #007a4b;
  background: #fff;
  font-weight: 600;
  margin: 15px auto 60px;
}

.catering-explore-btn:hover {
  background: #007a4b;
  color: #fff;
}

.catering-container {
  max-width: 1200px;
  margin: 0 auto;
}

.catering-package-header {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}

.catering-packages_wrapper {
  margin: 0 -20px;
}

.catering-packages_list ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  column-gap: 25px;
}
.catering-packages_list li {
  width: 25%;
}

.catering-packages_item {
  padding: 25px 20px;
  border-radius: 20px;
  border: 1px solid #d1d5db;
  background: #fff;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.catering-packages_item_name h5 {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 5px;
  margin-bottom: 0;
  font-size: 22px;
}

.catering-choose-package {
  font-size: 30px;
}

.catering-package-choose-header {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 50px 0;
}

.catering-subcategories {
}

.catering-subcategories__list {
  list-style: none;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 40px;
}

.catering-subcategories__item {
  width: 45%;
  padding: 0 20px;
  overflow: hidden;
  margin-bottom: 25px;
}

.catering-subcategories__image {
  position: relative;
  padding-bottom: 50%;
  overflow: hidden;
}

.catering-subcategories__image img {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  transform: translate(0, -50%);
}

.catering-subcategories__desc {
  padding: 16px 20px;
  background: rgba(73, 167, 211, 0.2);
  color: #0c351a;
  font-size: 20px;
}

.catering-subcategories__desc h5 {
  color: #0c351a;
  font-weight: 500;
  margin-bottom: 10px;
}

.catering-subcategories__desc p {
  font-size: 16px;
  color: #0c351a;
  margin-bottom: 10px;
}

.catering-subcategories__order-now {
  border: 1.5px solid #007a4b;
  background: #007a4b;
  width: 100%;
  margin-top: 0;
}

.catprod {
}

.catprod__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.catprod-card {
  max-width: 440px;
  margin-bottom: 40px;
}

.catprod-card__content {
  border: 1px solid var(--grey-grey-400, #a1a1aa);
  background: #fff;
}

.catprod-card__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 60%;
}

.catprod-card__image img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
}

.catprod-card h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.catprod-card__select-wrapper {
  width: 100%;
}

.catprod-card__select {
  display: flex;
  width: 100%;
  align-items: center;
  color: #000;
  padding: 20px 25px;
  font-size: 20px;
  font-weight: 600;
  z-index: 10;
}

.catprod-card__inner .modifier-btn-grp {
  padding: 0 25px;
}

.catprod-card__mod-wrapper {
  padding: 18px 25px;
  border-top: 1px solid var(--grey-grey-400, #a1a1aa);
}

.catprod-card__content .combo-input-label {
  width: 100%;
  background: #fff;
  color: #000;
  font-size: 18px;
}

.catprod-right h5 {
  margin-bottom: 10px;
  margin-top: 25px;
}

.catprod-right .qty_bx {
  float: none;
}

.catprod-right .input_field {
  width: 100%;
}

.catprod-right__order-summary {
  width: 100%;
}

.catprod-right .products_quantity {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.catprod-right__footer {
  padding: 0 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.catprod-right__footer .instruction {
  width: 100%;
}

.catprod-right__footer .add_to_cart {
  border-radius: 10px;
  background: #007a4b;
  margin-bottom: 30px;
}

.catprod-right__tac h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.catprod-right__tac p {
  font-size: 14px;
  line-height: 1.2;
  color: var(--grey-grey-700, #3f3f46);
}

.catprod-right__order-list {
  list-style: none;
  padding: 0;
}

.catprod-right__order-item {
  display: flex;
  align-items: center;
}

.catprod-right__order-item .close-icon {
  width: 30px;
  height: 30px;
  color: #dc2626;
}

.catprod-right__order-item__name {
  margin-left: 20px;
}

.catprod-right__subtotal {
  margin: 20px 0;
  display: flex;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;
}

.catprod-right__subtotal__label {
}

.catprod-right__subtotal__value {
}
.catprod-additional h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.catprod-additional .custom_radio {
  display: flex;
}
.catprod-additional .custom_radio input {
  margin-right: 5px;
}

.catprod-additional ul.additionals-deliverylist {
  padding: 0;
}

.catprod-additional .additionals-deliverylist .qty_bx {
  margin-bottom: 10px;
}

.catprod-additional .additionals-deliverylist .qty_val {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.catprod-additional .custom_checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.catprod-additional .custom_checkbox input {
  margin-right: 5px;
  margin-bottom: 5px;
}

.catering-cart {
  padding: 30px;
}

.catering-cart h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.catering-cart__table {
  border-collapse: collapse;
}

.catering-cart__table th {
  font-size: 20px;
  font-weight: 600;
  padding: 18px 0;
}

.catering-cart__table thead tr {
  border-bottom: 1px solid #d4d4d8;
}

.catering-cart__last-row {
  border-top: 1px solid #d4d4d8;
}

.catering-cart__table .catering-cart__last-row td {
  padding: 8px 0;
  font-size: 24px;
}

.catering-cart__table tbody td {
  padding: 18px 0;
  font-weight: 600;
}

.catering-cart__table td {
  font-size: 18px;
}

.catering-cart__table td .close-icon {
  width: 25px;
  height: 25px;
}

.catering-cart__table .product_quantity {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.catering-cart__cell-inner {
  display: flex;
  font-weight: 500;
  flex-direction: column;
}

.catering-cart__item-name {
  color: var(--koomi-1000, #d4291c);
  font-weight: 600;
}

.catering-cart__table .proceed-btn {
  border-radius: 10px;
  background: #007a4b;
  padding: 14px 50px;
}

.catering-cart__table-action {
  width: 180px;
}

.catering-checkout__header {
  font-size: 24px;
  margin-top: 60px;
}

.catering-checkout__address {
  margin-top: 60px;
}

.catering-checkout__back-btn {
  display: flex;
  align-items: center;
  margin: 20px 0;
  color: #007a4b;
}

.catering-checkout__back {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-bottom: 4px;
}
.catering-checkout__form-group {
  display: flex;
  width: 100%;
  max-width: 900px;
  gap: 20px;
}

.catering-checkout__input-group input {
  border-radius: 10px;
  border: 1px solid var(--grey-grey-400, #a1a1aa);
  background: #fff;
}

.catering-checkout__form-group .catering-checkout__input-group {
  width: 100%;
}

.catering-checkout__form-group--half .catering-checkout__input-group {
  width: 50%;
}

.catering-checkout__order-summary {
}

.catering-checkout__summary-item {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  max-width: 560px;
  justify-content: space-between;
}

.catering-checkout__summary-value {
  width: 200px;
}

.catering-checkout__note {
  max-width: 400px;
  color: var(--grey-grey-500, #71717a);
  font-size: 15px;
}

.catering-checkout__agree-tac {
  display: flex;
  align-items: center;
  margin: 30px 0 20px;
}

.catering-checkout__agree-tac input {
  margin-right: 10px;
  margin-bottom: 3px;
}
.catering-checkout__agree-tac a {
  margin-left: 5px;
  text-decoration: underline;
}

.catering-checkout__send-btn {
  border-radius: 10px;
  background: #007a4b;
  padding: 15px 40px;
}

.catering-success {
  text-align: center;
  margin-top: 40px;
}

.catering-success__header {
  font-size: 24px;
  font-weight: 700;
}

.catering-success__info {
  font-size: 24px;
}

.catering-success__back-btn {
  border-radius: 10px;
  background: #007a4b;
  padding: 15px 40px;
}
